/* Header
------------------------------------------------------------*/

.c-header {
    background-color: #000022;
    color: #fff;
    padding-bottom: 13px;
    &__text {
        max-width: 1170px;
        padding: 0 20px;
        font-size: 1.12rem;
        margin: 0 auto;
    }
    &__list {
        padding: 0 20px;
        max-width: 980px;
        margin: 7px auto;
        display: flex;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            padding: 0;
        }
    }
    &__menu {
        width: 68.5%;
        display: -webkit-box;
        display: flex;
        margin-top: 19px;
        @media only screen and (max-width: 767px) {
            width: 72.5%;
            padding-right: 10px;
        }
        @media only screen and (max-width: 516px) {
            display: none;
        }
        li {
            width: 90.14px;
            text-align: center;
        }
    }
}

.p_ttl {
    font-family: Arial, Montserrat, "Helvetica Neue", Helvetica, sans-serif;
    font-style: oblique;
    font-weight: 600;
    font-size: 1.456rem;
    letter-spacing: 0.728px;
}

.p_ttl2 {
    font-size: 1.2rem;
    letter-spacing: 0.56px;
}


/* Main menu
------------------------------------------------------------*/