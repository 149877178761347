/*------------------------------------------------------------
footer
------------------------------------------------------------*/

.quicklinks {}

.c {
    &-footer {
        background-image: url(../img/bg_footer.png);
        color: white;
        background-position: top;
        padding-top: 51px;
        background-repeat: no-repeat;
    }
    &-footer__list {
        display: -webkit-box;
        display: flex;
        max-width: 1207px;
        padding: 0 20px;
        margin: 0 auto 49px;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
    }
    &-footer__left {
        width: 263px;
        @media only screen and (max-width: 767px) {
            margin: 0 auto;
        }
    }
    &-footer__logo {
        text-align: center;
    }
    &-footer__text {
        font-size: 1.2rem;
    }
    &-footer__right {
        padding-right: 3%;
        font-size: 1.12rem;
        margin-top: 9px;
        @media only screen and (max-width: 767px) {
            width: 275px;
            margin: 30px auto;
        }
        ul {
            display: -webkit-box;
            display: flex;
            margin-bottom: 10px;
            justify-content: flex-end;
            @media only screen and (max-width: 767px) {
                margin-left: -14px;
            }
            li {
                margin-left: 14px;
            }
        }
    }
    &-footer__copy {
        font-size: 1rem;
        background-color: #000022;
        color: white;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        height: 29px;
    }
}

.img {
    &-responsive {}
    &-responsive.center-block {}
}

.center {
    &-block {}
}

.list {
    &-inline {}
    &-inline.quicklinks {}
}