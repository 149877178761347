/* layout
------------------------------------------------------------*/

body {
    font-family: "Open Sans", 游ゴシック, YuGothic, メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
    line-height: 1.6;
    color: #4c4948;
    font-size: 1.4rem;
}

//-------------------------------------------------------
main {}

section {}

//-------------------------------------------------------
.l-container {}

//-------------------------------------------------------
.c-mainvisual {}