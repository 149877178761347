/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-top {
    line-height: 2;
    // margin: 20px 0 ;
    // padding: 30px 0;
    //-------------------------------------------------------
    &1 {}
    //-------------------------------------------------------
    &2 {}
    //-------------------------------------------------------
    &3 {}
    //-------------------------------------------------------
    &4 {}
}

.p-index {
    &__list {
        max-width: 1189px;
        padding: 0 20px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin: 0 auto;
        @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }
    &__bg {
        background-image: url(../img/bg_header.png);
        height: auto;
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        color: white;
        padding: 30px 0;
    }
    &__meta {
        width: 48%;
        @media only screen and (max-width: 1024px) {
            width: 100%;
            max-width: 810px;
            margin: 0 auto;
        }
    }
    &__image {
        width: 47%;
        padding-left: 36px;
        margin-top: 55px;
        @media only screen and (max-width: 1024px) {
            width: 100%;
            padding-left: 0;
            max-width: 500px;
            margin: 0 auto 30px;
        }
    }
    &__social {
        justify-content: center;
        display: flex;
    }
    &__face {
        margin-left: 19%;
        margin-right: 4px;
    }
    &__post {
        display: flex;
        margin-bottom: 20px;
        @media only screen and (max-width: 516px) {
            flex-wrap: wrap;
        }
    }
    &__category {
        width: 88px;
        height: 20px;
        background: #E60036;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 1.1rem;
    }
    &__news {
        margin-top: 19px;
    }
    &__blog {
        margin-top: 19px;
    }
    &__date {
        width: 143.4px;
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
    &__info {
        margin-top: 8px;
    }
    &__button {
        color: #076BAF;
        background-color: #fff;
        font-size: 1.26rem;
        width: 101px;
        display: -webkit-box;
        display: flex;
        border-radius: 3px;
        -webkit-box-align: center;
        align-items: center;
        -webkit-box-pack: center;
        justify-content: center;
        margin-top: 34px;
        border: 2px solid white;
        height: 21px;
        @media only screen and (max-width: 1024px) {
            margin: 34px auto 0;
        }
        &:hover {
            background-color: #076BAF;
            color: white;
        }
        span {
            margin-right: 12px;
            font-weight: 600;
        }
    }
}

.index {}

.p {
    &-top__list {
        max-width: 1181px;
        margin: 48px auto;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        padding: 0 20px;
        @media only screen and (max-width: 1024px) {
            flex-wrap: wrap;
        }
    }
    &-top__left {
        width: 74.5%;
        @media only screen and (max-width: 1024px) {
            width: 100%;
        }
    }
    &-top__text {
        line-height: 1.7;
        margin-bottom: 36px;
    }
    &-top__menu {}
    &-menu__big {
        margin-top: 21px;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
        a {
            width: 57%;
            @media only screen and (max-width: 767px) {
                width: 100%;
                margin-bottom: 20px;
            }
        }
        div {
            width: 39.5%;
            @media only screen and (max-width: 767px) {
                width: 100%;
            }
        }
    }
    &-menu__small {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        margin-top: 21px;
        margin-bottom: 32px;
        @media only screen and (max-width: 767px) {
            flex-wrap: wrap;
        }
        a {
            width: 22.5%;
            @media only screen and (max-width: 767px) {
                width: 48%;
                margin-bottom: 20px;
            }
        }
    }
    &-menu__rally {}
    &-youtube {
        margin-bottom: 52px;
        margin-top: 13px;
        display: -webkit-box;
        display: flex;
        flex-wrap: wrap;
        @media only screen and (max-width: 1024px) {
            justify-content: space-between;
        }
    }
    &_youtube__item {
        width: 31%;
        margin-right: 29px;
        @media only screen and (max-width: 1024px) {
            width: 48%;
            margin-right: 0;
            height: 231px;
        }
        @media only screen and (max-width: 768px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    &-insta {}
    &-map {}
    &-map__text {
        margin-top: 18px;
        margin-bottom: 11px;
    }
    &-map__google {
        margin-bottom: 15px;
        height: 349px;
    }
    &-top__right {
        width: 23%;
        @media only screen and (max-width: 1024px) {
            margin-top: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
        }
    }
    &-top__contact {
        margin-top: 21px;
        display: block;
        margin-bottom: 21px;
    }
    &-top__container {
        @media only screen and (max-width: 1024px) {
            max-width: 400px;
            margin: 0 auto;
        }
    }
    &-top__rescue {
        display: block;
        margin-bottom: 18px;
    }
    &-top__link {
        width: 48%;
        margin: 10px;
        max-width: 400px;
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 300px;
        }
        @media only screen and (max-width: 516px) {
            max-width: 100%;
            margin: 0;
        }
    }
    &-top__phone {
        display: block;
    }
    &-top__gallery {
        margin-bottom: 14px;
    }
}

.img {
    &-responsive {}
    &-responsive.center-block {}
    &-responsive.center-block.space_bottom2 {}
}

.center {
    &-block {}
}

.space {
    &_bottom2 {}
}