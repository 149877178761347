@charset "UTF-8";
/*------------------------------------------------------------
	Reset
------------------------------------------------------------*/
html,
body,
div,
span,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
abbr,
address,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
samp,
small,
strong,
sub,
sup,
var,
b,
i,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
dialog,
figure,
footer,
header,
nav,
section,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  box-sizing: border-box;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section,
main {
  display: block;
}

html {
  font-size: 62.5%;
}

body {
  -webkit-text-size-adjust: 100%;
}

body,
table,
input,
textarea,
select,
option,
h1,
h2,
h3,
h4,
h5,
h6 {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: bold;
}

table,
input,
textarea,
select,
option {
  line-height: 1.1;
}

ol,
ul {
  list-style: none;
}

blockquote,
q {
  quotes: none;
}

:focus {
  outline: 0;
}

ins {
  text-decoration: none;
}

del {
  text-decoration: line-through;
}

img {
  vertical-align: top;
}

a,
a img {
  transition: 0.3s ease-in-out;
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:hover img {
  opacity: 0.80;
  filter: alpha(opacity=80);
  -ms-filter: "alpha(opacity=80)";
}

img {
  max-width: 100%;
}

.sp-only {
  display: none;
}

@media only screen and (max-width: 767px) {
  .pc-only {
    display: none;
  }
  .sp-only {
    display: block;
  }
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Layout .l-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/* Header
------------------------------------------------------------*/
.c-header {
  background-color: #000022;
  color: #fff;
  padding-bottom: 13px;
}

.c-header__text {
  max-width: 1170px;
  padding: 0 20px;
  font-size: 1.12rem;
  margin: 0 auto;
}

.c-header__list {
  padding: 0 20px;
  max-width: 980px;
  margin: 7px auto;
  display: flex;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .c-header__list {
    padding: 0;
  }
}

.c-header__menu {
  width: 68.5%;
  display: -webkit-box;
  display: flex;
  margin-top: 19px;
}

@media only screen and (max-width: 767px) {
  .c-header__menu {
    width: 72.5%;
    padding-right: 10px;
  }
}

@media only screen and (max-width: 516px) {
  .c-header__menu {
    display: none;
  }
}

.c-header__menu li {
  width: 90.14px;
  text-align: center;
}

.p_ttl {
  font-family: Arial, Montserrat, "Helvetica Neue", Helvetica, sans-serif;
  font-style: oblique;
  font-weight: 600;
  font-size: 1.456rem;
  letter-spacing: 0.728px;
}

.p_ttl2 {
  font-size: 1.2rem;
  letter-spacing: 0.56px;
}

/* Main menu
------------------------------------------------------------*/
/*------------------------------------------------------------
footer
------------------------------------------------------------*/
.c-footer {
  background-image: url(../img/bg_footer.png);
  color: white;
  background-position: top;
  padding-top: 51px;
  background-repeat: no-repeat;
}

.c-footer__list {
  display: -webkit-box;
  display: flex;
  max-width: 1207px;
  padding: 0 20px;
  margin: 0 auto 49px;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .c-footer__list {
    flex-wrap: wrap;
  }
}

.c-footer__left {
  width: 263px;
}

@media only screen and (max-width: 767px) {
  .c-footer__left {
    margin: 0 auto;
  }
}

.c-footer__logo {
  text-align: center;
}

.c-footer__text {
  font-size: 1.2rem;
}

.c-footer__right {
  padding-right: 3%;
  font-size: 1.12rem;
  margin-top: 9px;
}

@media only screen and (max-width: 767px) {
  .c-footer__right {
    width: 275px;
    margin: 30px auto;
  }
}

.c-footer__right ul {
  display: -webkit-box;
  display: flex;
  margin-bottom: 10px;
  justify-content: flex-end;
}

@media only screen and (max-width: 767px) {
  .c-footer__right ul {
    margin-left: -14px;
  }
}

.c-footer__right ul li {
  margin-left: 14px;
}

.c-footer__copy {
  font-size: 1rem;
  background-color: #000022;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  height: 29px;
}

/*------------------------------------------------------------
sidebar
------------------------------------------------------------*/
/* Breadcrumb
------------------------------------------------------------*/
/* layout
------------------------------------------------------------*/
body {
  font-family: "Open Sans", 游ゴシック, YuGothic, メイリオ, Meiryo, "ＭＳ Ｐゴシック", sans-serif;
  line-height: 1.6;
  color: #4c4948;
  font-size: 1.4rem;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Component .c-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
btn
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
.c-btn1 {
  display: inline-block;
  padding: 10px 50px;
  font-size: 1.6rem;
  border: 2px solid #fff;
  border-radius: 20px;
  line-height: 1;
}

/*------------------------------------------------------------
title
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
icon
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
form
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
text
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
navi
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
img
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
list
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
table
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
line
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
video
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*------------------------------------------------------------
other
------------------------------------------------------------*/
/*
------------------------------------------------------------*/
/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Project .p-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-top {
  line-height: 2;
}

.p-index__list {
  max-width: 1189px;
  padding: 0 20px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin: 0 auto;
}

@media only screen and (max-width: 1024px) {
  .p-index__list {
    flex-wrap: wrap;
  }
}

.p-index__bg {
  background-image: url(../img/bg_header.png);
  height: auto;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  padding: 30px 0;
}

.p-index__meta {
  width: 48%;
}

@media only screen and (max-width: 1024px) {
  .p-index__meta {
    width: 100%;
    max-width: 810px;
    margin: 0 auto;
  }
}

.p-index__image {
  width: 47%;
  padding-left: 36px;
  margin-top: 55px;
}

@media only screen and (max-width: 1024px) {
  .p-index__image {
    width: 100%;
    padding-left: 0;
    max-width: 500px;
    margin: 0 auto 30px;
  }
}

.p-index__social {
  justify-content: center;
  display: flex;
}

.p-index__face {
  margin-left: 19%;
  margin-right: 4px;
}

.p-index__post {
  display: flex;
  margin-bottom: 20px;
}

@media only screen and (max-width: 516px) {
  .p-index__post {
    flex-wrap: wrap;
  }
}

.p-index__category {
  width: 88px;
  height: 20px;
  background: #E60036;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.1rem;
}

.p-index__news {
  margin-top: 19px;
}

.p-index__blog {
  margin-top: 19px;
}

.p-index__date {
  width: 143.4px;
}

@media only screen and (max-width: 516px) {
  .p-index__date {
    width: 100%;
  }
}

.p-index__info {
  margin-top: 8px;
}

.p-index__button {
  color: #076BAF;
  background-color: #fff;
  font-size: 1.26rem;
  width: 101px;
  display: -webkit-box;
  display: flex;
  border-radius: 3px;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  margin-top: 34px;
  border: 2px solid white;
  height: 21px;
}

@media only screen and (max-width: 1024px) {
  .p-index__button {
    margin: 34px auto 0;
  }
}

.p-index__button:hover {
  background-color: #076BAF;
  color: white;
}

.p-index__button span {
  margin-right: 12px;
  font-weight: 600;
}

.p-top__list {
  max-width: 1181px;
  margin: 48px auto;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  padding: 0 20px;
}

@media only screen and (max-width: 1024px) {
  .p-top__list {
    flex-wrap: wrap;
  }
}

.p-top__left {
  width: 74.5%;
}

@media only screen and (max-width: 1024px) {
  .p-top__left {
    width: 100%;
  }
}

.p-top__text {
  line-height: 1.7;
  margin-bottom: 36px;
}

.p-menu__big {
  margin-top: 21px;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
}

@media only screen and (max-width: 767px) {
  .p-menu__big {
    flex-wrap: wrap;
  }
}

.p-menu__big a {
  width: 57%;
}

@media only screen and (max-width: 767px) {
  .p-menu__big a {
    width: 100%;
    margin-bottom: 20px;
  }
}

.p-menu__big div {
  width: 39.5%;
}

@media only screen and (max-width: 767px) {
  .p-menu__big div {
    width: 100%;
  }
}

.p-menu__small {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  margin-top: 21px;
  margin-bottom: 32px;
}

@media only screen and (max-width: 767px) {
  .p-menu__small {
    flex-wrap: wrap;
  }
}

.p-menu__small a {
  width: 22.5%;
}

@media only screen and (max-width: 767px) {
  .p-menu__small a {
    width: 48%;
    margin-bottom: 20px;
  }
}

.p-youtube {
  margin-bottom: 52px;
  margin-top: 13px;
  display: -webkit-box;
  display: flex;
  flex-wrap: wrap;
}

@media only screen and (max-width: 1024px) {
  .p-youtube {
    justify-content: space-between;
  }
}

.p_youtube__item {
  width: 31%;
  margin-right: 29px;
}

@media only screen and (max-width: 1024px) {
  .p_youtube__item {
    width: 48%;
    margin-right: 0;
    height: 231px;
  }
}

@media only screen and (max-width: 768px) {
  .p_youtube__item {
    width: 100%;
    margin-bottom: 20px;
  }
}

.p-map__text {
  margin-top: 18px;
  margin-bottom: 11px;
}

.p-map__google {
  margin-bottom: 15px;
  height: 349px;
}

.p-top__right {
  width: 23%;
}

@media only screen and (max-width: 1024px) {
  .p-top__right {
    margin-top: 50px;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
}

.p-top__contact {
  margin-top: 21px;
  display: block;
  margin-bottom: 21px;
}

@media only screen and (max-width: 1024px) {
  .p-top__container {
    max-width: 400px;
    margin: 0 auto;
  }
}

.p-top__rescue {
  display: block;
  margin-bottom: 18px;
}

.p-top__link {
  width: 48%;
  margin: 10px;
  max-width: 400px;
}

@media only screen and (max-width: 767px) {
  .p-top__link {
    width: 100%;
    max-width: 300px;
  }
}

@media only screen and (max-width: 516px) {
  .p-top__link {
    max-width: 100%;
    margin: 0;
  }
}

.p-top__phone {
  display: block;
}

.p-top__gallery {
  margin-bottom: 14px;
}

/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/
.p-about {
  line-height: 2;
}

.c-title {
  background-color: #004ea2;
  color: #fff;
  font-size: 1.916rem;
  font-weight: 500;
  height: 44px;
}

.c-title p {
  max-width: 1171px;
  padding: 0 20px;
  margin: 0 auto;
  height: 100%;
  display: flex;
  align-items: center;
}

.local-nav {
  margin-bottom: 40px;
  width: 100%;
}

.local-nav li {
  background-color: #004EA2;
  color: white;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1.96rem;
  height: 39px;
  margin-bottom: 10px;
}

.p-service__left {
  width: 72%;
}

@media only screen and (max-width: 1024px) {
  .p-service__left {
    width: 100%;
  }
}

.p-service__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-service__item {
  width: 48%;
  margin-bottom: 2px;
}

@media only screen and (max-width: 516px) {
  .p-service__item {
    width: 100%;
  }
}

.c-title1 {
  font-size: 2.66rem;
  color: #004EA2;
  margin-bottom: 22px;
}

@media only screen and (max-width: 516px) {
  .c-title1 {
    font-size: 2rem;
  }
}

.p-rally__list {
  display: flex;
  justify-content: space-between;
  margin-top: 13px;
  flex-wrap: wrap;
}

.p-rally__item {
  width: 48%;
  height: 231px;
}

@media only screen and (max-width: 768px) {
  .p-rally__item {
    width: 100%;
    margin-bottom: 20px;
  }
}

.p-rally__news {
  margin-bottom: 52px;
}

.p-about__title {
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  color: #e55927;
  font-size: 2.1rem;
  font-weight: 500;
}

.p-about__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 16px;
}

.p-about__image {
  width: 48%;
}

@media only screen and (max-width: 767px) {
  .p-about__image {
    width: 100%;
    max-width: 400px;
    margin: 0 auto 10px;
  }
}

.p-about__text {
  width: 48.5%;
  line-height: 1.6;
  margin-top: 14px;
}

@media only screen and (max-width: 767px) {
  .p-about__text {
    width: 100%;
  }
}

.p-about__table {
  margin-top: 22px;
}

.p-about__table table {
  line-height: 1.4;
}

.p-about__table table,
.p-about__table th,
.p-about__table td {
  border: 1px solid #dddddd;
  border-collapse: collapse;
}

@media only screen and (max-width: 516px) {
  .p-about__table tbody {
    display: flex;
    flex-direction: column;
  }
}

@media only screen and (max-width: 516px) {
  .p-about__table tr {
    display: flex;
    flex-wrap: wrap;
  }
}

.p-about__table th {
  background-color: #eeeeee;
  width: 154px;
  font-weight: 500;
  text-align: left;
  padding: 8px;
  vertical-align: top;
}

@media only screen and (max-width: 516px) {
  .p-about__table th {
    width: 100%;
  }
}

.p-about__table td {
  padding: 9px;
  width: 82%;
}

@media only screen and (max-width: 516px) {
  .p-about__table td {
    width: 100%;
  }
}

.p-access__text {
  margin-top: 34px;
  line-height: 1.6;
}

.p-new__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
}

@media only screen and (max-width: 767px) {
  .p-new__text {
    width: 100%;
    margin-bottom: 20px;
  }
}

.p_new1 {
  font-size: 2.66rem;
  color: #004EA2;
  font-weight: 600;
  line-height: 2;
}

@media only screen and (max-width: 516px) {
  .p_new1 {
    font-size: 2rem;
  }
}

.p_new2 {
  margin-top: -9px;
  font-size: 1.68rem;
  color: #004EA2;
  font-weight: 600;
  line-height: 2;
}

.p-new__logo {
  width: 240px;
  margin-right: 10.5%;
  margin-top: 4px;
}

@media only screen and (max-width: 767px) {
  .p-new__logo {
    margin: 0 auto;
  }
}

.p-new__meta {
  color: #4c4948;
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  -webkit-box-align: end;
  align-items: flex-end;
  font-weight: 600;
  flex-wrap: wrap;
}

.p_new3 {
  font-size: 2.94rem;
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  margin-top: 21px;
  margin-bottom: 12px;
}

@media only screen and (max-width: 516px) {
  .p_new3 {
    font-size: 2.3rem;
  }
}

.p_new4 {
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.82rem;
}

@media only screen and (max-width: 516px) {
  .p_new4 {
    font-size: 1.6rem;
  }
}

.p-new__people {
  width: 125px;
  margin-bottom: 4px;
  margin-right: 4%;
}

@media only screen and (max-width: 767px) {
  .p-new__people {
    margin: 0 auto;
  }
}

.p-new__car {
  max-width: 600px;
  margin: 0 auto;
}

.p_used1 {
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-weight: 700;
  font-size: 3.22rem;
  color: #004EA2;
  margin-bottom: 10px;
  padding-left: 15px;
}

@media only screen and (max-width: 516px) {
  .p_used1 {
    font-size: 2.7rem;
    padding-left: 0;
  }
}

.p-used__list {
  display: -webkit-box;
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .p-used__list {
    margin-bottom: 40px;
  }
}

.p-used__text {
  width: 64%;
  line-height: 1.6;
}

@media only screen and (max-width: 767px) {
  .p-used__text {
    width: 100%;
  }
}

.p-used__image {
  width: 31%;
}

@media only screen and (max-width: 767px) {
  .p-used__image {
    width: 100%;
    margin: 20px auto 0;
    max-width: 400px;
  }
}

.p-used__title {
  font-size: 1.996rem;
}

@media only screen and (max-width: 516px) {
  .p-used__title {
    font-size: 1.75rem;
  }
}

.p-main__list {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  flex-wrap: wrap;
  line-height: 1.6;
  margin-bottom: 50px;
}

.p-main__left {
  width: 31%;
}

@media only screen and (max-width: 767px) {
  .p-main__left {
    width: 48%;
  }
}

@media only screen and (max-width: 516px) {
  .p-main__left {
    width: 100%;
    text-align: center;
  }
}

.p-main__center {
  width: 30%;
}

@media only screen and (max-width: 767px) {
  .p-main__center {
    width: 48%;
  }
}

@media only screen and (max-width: 516px) {
  .p-main__center {
    margin-top: 20px;
    width: 100%;
  }
}

.p-main__right {
  width: 31%;
}

@media only screen and (max-width: 767px) {
  .p-main__right {
    width: 100%;
    text-align: center;
    margin: 30px auto 0;
  }
}

.p-main__image {
  margin-bottom: 56px !important;
}

.p-coat__text {
  line-height: 1.6;
}

.p-cus__list {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-cus__item {
  width: 30%;
  margin-bottom: 20px;
}

@media only screen and (max-width: 767px) {
  .p-cus__item {
    width: 46%;
  }
}

@media only screen and (max-width: 516px) {
  .p-cus__item {
    width: 100%;
  }
}

.p-cus__item img {
  display: block;
  margin: 0 auto;
}

h2.privacy {
  font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
  font-size: 1.8rem;
  font-weight: 500;
  color: #004EA2;
  margin-top: 25px;
  margin-bottom: 5px;
}

.p_rescue1 {
  font-weight: 600;
  color: #E60012;
  border-bottom: 2px solid #E60012;
}

.p-recue__map {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.p-recue__small {
  width: 48%;
  text-align: center;
}

@media only screen and (max-width: 767px) {
  .p-recue__small {
    width: 100%;
    margin-bottom: 30px;
  }
}

.p-recue__image {
  margin-bottom: 21px;
}

.ggmap {
  margin-top: 12px;
  height: 181px;
}

.p-recue__new1 {
  line-height: 2.8;
}

.p-recue__table {
  margin-bottom: 26px;
}

.p-recue__table table {
  line-height: 1.4;
  width: 100%;
}

.p-recue__table table,
.p-recue__table th,
.p-recue__table td {
  border: 1px solid #dddddd;
  border-collapse: collapse;
}

.p-recue__table th {
  background-color: #eeeeee;
  width: 270px;
  font-weight: 500;
  text-align: left;
  padding: 8px;
  vertical-align: top;
}

.p-recue__table td {
  padding: 9px;
  width: 68%;
}

.local-nav li:hover {
  background-color: white;
  color: #004ea2;
  border: 1px solid;
  cursor: pointer;
}

/*≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡
// Utility .u-
≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡≡*/
