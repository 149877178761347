/*------------------------------------------------------------
Css of home page
------------------------------------------------------------*/

.p-about {
    line-height: 2;
}

.c-title {
    background-color: #004ea2;
    color: #fff;
    font-size: 1.916rem;
    font-weight: 500;
    height: 44px;
    p {
        max-width: 1171px;
        padding: 0 20px;
        margin: 0 auto;
        height: 100%;
        display: flex;
        align-items: center;
    }
}

.local-nav {
    margin-bottom: 40px;
    width: 100%;
    li {
        background-color: #004EA2;
        color: white;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        align-items: center;
        font-size: 1.96rem;
        height: 39px;
        margin-bottom: 10px;
    }
}

.p-service {
    &__left {
        width: 72%;
        @media only screen and (max-width: 1024px) {
            width: 100%;
        }
    }
    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        width: 48%;
        margin-bottom: 2px;
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
    }
}

.c-title1 {
    font-size: 2.66rem;
    color: #004EA2;
    margin-bottom: 22px;
    @media only screen and (max-width: 516px) {
        font-size: 2rem;
    }
}

.p-rally__list {
    display: flex;
    justify-content: space-between;
    margin-top: 13px;
    flex-wrap: wrap;
}

.p-rally__item {
    width: 48%;
    height: 231px;
    @media only screen and (max-width: 768px) {
        width: 100%;
        margin-bottom: 20px;
    }
}

.p-rally__news {
    margin-bottom: 52px;
}

.p-about {
    &__title {
        font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
        color: #e55927;
        font-size: 2.1rem;
        font-weight: 500;
    }
    &__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 16px;
    }
    &__image {
        width: 48%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            max-width: 400px;
            margin: 0 auto 10px;
        }
    }
    &__text {
        width: 48.5%;
        line-height: 1.6;
        margin-top: 14px;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &__table {
        margin-top: 22px;
        table {
            line-height: 1.4;
        }
        table,
        th,
        td {
            border: 1px solid rgb(221, 221, 221);
            border-collapse: collapse;
        }
        tbody {
            @media only screen and (max-width: 516px) {
                display: flex;
                flex-direction: column;
            }
        }
        tr {
            @media only screen and (max-width: 516px) {
                display: flex;
                flex-wrap: wrap;
            }
        }
        th {
            background-color: #eeeeee;
            width: 154px;
            font-weight: 500;
            text-align: left;
            padding: 8px;
            vertical-align: top;
            @media only screen and (max-width: 516px) {
                width: 100%;
            }
        }
        td {
            padding: 9px;
            width: 82%;
            @media only screen and (max-width: 516px) {
                width: 100%;
            }
        }
    }
}

.p-access__text {
    margin-top: 34px;
    line-height: 1.6;
}

.row {}

.col {
    &-md-9 {}
    &-md-12 {}
    &-md-9.space_top.space_top2.space_bottom {}
    &-md-12.space_top2.space_bottom2 {}
}

.space {
    &_top {}
    &_top2 {}
    &_bottom {}
    &_bottom2 {}
}

.p {
    &-new__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &-new__text {
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin-bottom: 20px;
        }
    }
    &_new1 {
        font-size: 2.66rem;
        color: #004EA2;
        font-weight: 600;
        line-height: 2;
        @media only screen and (max-width: 516px) {
            font-size: 2rem;
        }
    }
    &_new2 {
        margin-top: -9px;
        font-size: 1.68rem;
        color: #004EA2;
        font-weight: 600;
        line-height: 2;
    }
    &-new__logo {
        width: 240px;
        margin-right: 10.5%;
        margin-top: 4px;
        @media only screen and (max-width: 767px) {
            margin: 0 auto;
        }
    }
    &-new__meta {
        color: #4c4948;
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        -webkit-box-align: end;
        align-items: flex-end;
        font-weight: 600;
        flex-wrap: wrap;
    }
    &-new__left {}
    &_new3 {
        font-size: 2.94rem;
        font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
        margin-top: 21px;
        margin-bottom: 12px;
        @media only screen and (max-width: 516px) {
            font-size: 2.3rem;
        }
    }
    &_new4 {
        font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
        font-size: 1.82rem;
        @media only screen and (max-width: 516px) {
            font-size: 1.6rem;
        }
    }
    &-new__people {
        width: 125px;
        margin-bottom: 4px;
        margin-right: 4%;
        @media only screen and (max-width: 767px) {
            margin: 0 auto;
        }
    }
    &-new__car {
        max-width: 600px;
        margin: 0 auto;
    }
}

.img {
    &-responsive {}
    &-responsive.center-block {}
}

.center {
    &-block {}
}

.p_used1 {
    font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
    font-weight: 700;
    font-size: 3.22rem;
    color: #004EA2;
    margin-bottom: 10px;
    padding-left: 15px;
    @media only screen and (max-width: 516px) {
        font-size: 2.7rem;
        padding-left: 0;
    }
}

.p-used {
    &__list {
        display: -webkit-box;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            margin-bottom: 40px;
        }
    }
    &__text {
        width: 64%;
        line-height: 1.6;
        @media only screen and (max-width: 767px) {
            width: 100%;
        }
    }
    &__image {
        width: 31%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            margin: 20px auto 0;
            max-width: 400px;
        }
    }
    &__title {
        font-size: 1.996rem;
        @media only screen and (max-width: 516px) {
            font-size: 1.75rem;
        }
    }
}

.p-main {
    &__list {
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        line-height: 1.6;
        margin-bottom: 50px;
    }
    &__left {
        width: 31%;
        @media only screen and (max-width: 767px) {
            width: 48%;
        }
        @media only screen and (max-width: 516px) {
            width: 100%;
            text-align: center;
        }
    }
    &__center {
        width: 30%;
        @media only screen and (max-width: 767px) {
            width: 48%;
        }
        @media only screen and (max-width: 516px) {
            margin-top: 20px;
            width: 100%;
        }
    }
    &__right {
        width: 31%;
        @media only screen and (max-width: 767px) {
            width: 100%;
            text-align: center;
            margin: 30px auto 0;
        }
    }
    &__image {
        margin-bottom: 56px !important;
    }
}

.p-coat__text {
    line-height: 1.6;
}

.p-cus {
    &__list {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
    }
    &__item {
        width: 30%;
        margin-bottom: 20px;
        @media only screen and (max-width: 767px) {
            width: 46%;
        }
        @media only screen and (max-width: 516px) {
            width: 100%;
        }
        img {
            display: block;
            margin: 0 auto;
        }
    }
}

h2.privacy {
    font-family: Georgia, 游明朝, "Yu Mincho", YuMincho, "Hiragino Mincho ProN", HGS明朝E, メイリオ, Meiryo, serif;
    font-size: 1.8rem;
    font-weight: 500;
    color: #004EA2;
    margin-top: 25px;
    margin-bottom: 5px;
}

.p_rescue1 {
    font-weight: 600;
    color: #E60012;
    border-bottom: 2px solid #E60012;
}

.p-recue__map {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.p-recue__small {
    width: 48%;
    text-align: center;
    @media only screen and (max-width: 767px) {
        width: 100%;
        margin-bottom: 30px;
    }
}

.p-recue__image {
    margin-bottom: 21px;
}

.ggmap {
    margin-top: 12px;
    height: 181px;
}

.p-recue__new1 {
    line-height: 2.8;
}

.p-recue {
    &__table {
        margin-bottom: 26px;
        table {
            line-height: 1.4;
            width: 100%;
        }
        table,
        th,
        td {
            border: 1px solid rgb(221, 221, 221);
            border-collapse: collapse;
        }
        tbody {}
        tr {}
        th {
            background-color: #eeeeee;
            width: 270px;
            font-weight: 500;
            text-align: left;
            padding: 8px;
            vertical-align: top;
        }
        td {
            padding: 9px;
            width: 68%;
        }
    }
}

.local-nav li:hover {
    background-color: white;
    color: #004ea2;
    border: 1px solid;
    cursor: pointer;
}