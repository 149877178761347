/*------------------------------------------------------------
btn
------------------------------------------------------------*/

/*
------------------------------------------------------------*/
.c-btn1{
    display: inline-block;
    padding: 10px 50px;
    font-size: 1.6rem;
    border: 2px solid #fff;
    border-radius: 20px;
    line-height: 1;
}